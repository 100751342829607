@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600,700')

@import "~bootstrap/scss/functions"
@import "~bootstrap/scss/variables"
@import "~bootstrap/scss/mixins"
@import "~bootstrap/scss/reboot"

$yellow: #FFC627
$dark-blue: #1D2935
$primary: $yellow

body
  font-family: 'Raleway', sans-serif
  font-weight: 300

a
  color: white
  &:hover
    color: $yellow
    text-decoration: none

.container
  margin: auto 2rem

  @media( min-width: 1200px )
    width: 1100px
    margin: auto


.policy-content
 margin-bottom: 5rem

.accept-bar
  position: fixed
  width: 100%
  bottom: 0
  padding: 1rem
  border-top: 1px solid $yellow
  background-color: $dark-blue
  text-align: right

  button
    background-color: $yellow
    color: $dark-blue
    text-transform: uppercase
    padding: .5rem
    border: none

    &:hover
      background-color: darken( $yellow, 10%)
.public-wrapper
  min-height: 100vh
  width: 100%
  display: flex
  align-items: stretch
  background-color: $dark-blue
  color: $white

  .brand
    text-align: center
    margin-bottom: .5rem

    img
      max-width: 100%

  .title
    text-align: center
    text-transform: uppercase
    margin-bottom: 2rem

    h1
      font-weight: 300
      font-size: 2rem
      letter-spacing: 1px

  > section
    width: 100%

    @media( min-width: 1024px )
      width: 50%

    &.left
      display: flex
      align-items: center
      justify-content: center

    &.right
      background: url("/img/public.jpg") no-repeat center center
      background-size: cover
      display: none

      @media( min-width: 1024px )
        display: block

.public-form
  width: 350px
  max-width: 100%

  .field
    position: relative
    margin-bottom: 1rem
    padding-top: 1rem

    input.input
      display: block
      width: 100%
      background: none
      border: none
      border-bottom: 2px solid $white
      font-size: 1.5rem
      color: white
      padding: .5rem 0

      &::placeholder
        color: transparent

      &:not(:placeholder-shown) ~ label.label
        bottom: 2.5rem
        font-size: .85rem

      &:focus
        border-color: $primary
        outline: none

    label.label
      position: absolute
      font-size: 1.5rem
      font-weight: 400
      bottom: 0
      left: 0
      text-transform: uppercase
      transition: all .35s

    label.checkbox
      display: flex
      text-transform: uppercase
      color: $yellow
      align-items: center
      justify-content: center

      input.checkbox
        margin-right: 1rem
        margin-bottom: .25rem

  .submit
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    .button
      margin-bottom: 1rem

.button
  padding: .5rem 1.5rem
  border-radius: 3rem
  background: none
  border: 1px solid transparent
  text-transform: uppercase
  font-weight: 700

  &:hover
    text-decoration: none

  &.primary
    border-color: $yellow
    color: $yellow

    &:hover
      background-color: $yellow
      color: $dark-blue

  &.secondary
    border-color: white
    color: $white

    &:hover
      background-color: $white
      color: $dark-blue